<template>
  <div>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="pm-card" style="padding-top: 0;">
      <div class="p-fluid formgrid grid">

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  data(){
    return{
      home: {icon: 'pi pi-home', to: '/relatorios/dashboard'},
      items_bread: [
        {label: 'Dashboard'},
      ],
    }
  },
  beforeMount() {
    this.$router.push({name: "ficha-individual"});
  },
});
</script>
